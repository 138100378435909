import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    student: null,
    isFetching: false,
    isPosting: false,
    isObligationPosting: false,
    obligationStatusCode: null,
    isObligationEditing: false,
    obligationEditStatusCode: null,
    isProfileEditing: false,
    profileEditStatusCode: null,
};

const getters = {
    student: (state) => state.student,
    studentFetchStatus: (state) => state.isFetching,
    studentPostStatus: (state) => state.isPosting,
    studentObligationPostStatus: (state) => state.isObligationPosting,
    studentObligationPostStatusCode: (state) => state.obligationStatusCode,
    studentObligationEditStatus: (state) => state.isObligationPosting,
    studentObligationEditStatusCode: (state) => state.obligationEditStatusCode,
    studentProfileEditStatus: (state) => state.isProfileEditing,
    studentProfileEditStatusCode: (state) => state.profileEditStatusCode,
};

const actions = {
    async fetchStudent({ commit }, id) {
        try {
            commit("SET_FETCH_STATUS", true);
            const response = await axios.get(`${api}/user/${id}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                },
            });
            commit("SET_STUDENT", response.data[0]);
            commit("SET_FETCH_STATUS", false);
        } catch (error) {
            console.log("Couldn't get logged student");
            commit("SET_FETCH_STATUS", false);
        }
    },
    async postStatusBulk({ commit }, students, bulkID) {
        try {
            commit("SET_POST_STATUS", true);
            const response = await axios.post(`/api/bulkoptions`, {
                students,
                bulkID,
            });
            commit("SET_POST_STATUS", false);
        } catch (error) {
            console.log("Error while posting bulk");
            commit("SET_POST_STATUS", false);
        }
    },
    //TODO: izmijeniti API putanju
    async postObligationBulk({ commit }, data) {
        try {
            commit("SET_POST_STATUS", true);
            const response = await axios.post(`/student/bulk/obligation/add`, {
                data,
            });
            commit("SET_POST_STATUS", false);
        } catch (error) {
            console.log("Error while posting bulk");
            commit("SET_POST_STATUS", false);
        }
    },
    async postStudentObligation({ commit }, data) {
        const {
            id,
            name,
            year_of_studies_id,
            deadline,
            note,
            amount,
            type_of_payment_id,
            flexible,
        } = data;
        try {
            commit("SET_POST_OBLIGATION_STATUS", true);
            const response = await axios.put(`/student/${id}/addobligation`, {
                name,
                year_of_studies_id,
                deadline,
                note,
                amount,
                type_of_payment_id,
                flexible,
            });
            commit("SET_POST_OBLIGATION_STATUS_CODE", response.status);
            commit("SET_POST_OBLIGATION_STATUS", false);
        } catch (error) {
            console.log("Error while posting obligation");
            commit("SET_POST_OBLIGATION_STATUS", false);
        }
    },
    async editStudentObligation({ commit }, data) {
        try {
            commit("SET_EDIT_OBLIGATION_STATUS", true);
            const response = await axios.put(`/student/obligation/edit`, {
                data,
            });
            commit("SET_EDIT_OBLIGATION_STATUS_CODE", response.status);
            commit("SET_EDIT_OBLIGATION_STATUS", false);
        } catch (error) {
            console.log("Error while editing obligation");
            commit("SET_EDIT_OBLIGATION_STATUS", false);
        }
    },
    async deleteStudentObligation({ commit }, id) {
        try {
            commit("SET_EDIT_OBLIGATION_STATUS", true);
            const response = await axios.delete(`/student/obligation`, {
                params: {
                    id,
                },
            });
            commit("SET_EDIT_OBLIGATION_STATUS_CODE", response.status);
            commit("SET_EDIT_OBLIGATION_STATUS", false);
        } catch (error) {
            console.log("Error while editing obligation");
            commit("SET_EDIT_OBLIGATION_STATUS", false);
        }
    },
    async editStudentProfile({ commit }, data) {
        try {
            commit("SET_EDIT_PROFILE_STATUS", true);
            const response = await axios.post(`/student/edit`, {
                data,
            });
            commit("SET_EDIT_PROFILE_STATUS_CODE", response.status);
            commit("SET_EDIT_PROFILE_STATUS", false);
        } catch (error) {
            commit("SET_EDIT_PROFILE_STATUS_CODE", 444);
            commit("SET_EDIT_PROFILE_STATUS", false);
        }
    },
};

const mutations = {
    SET_STUDENT: (state, student) => (state.student = student),
    SET_FETCH_STATUS: (state, status) => (state.isFetching = status),
    SET_POST_STATUS: (state, status) => (state.isPosting = status),
    SET_POST_OBLIGATION_STATUS: (state, status) =>
        (state.isObligationPosting = status),
    SET_POST_OBLIGATION_STATUS_CODE: (state, status) =>
        (state.obligationStatusCode = status),
    SET_EDIT_OBLIGATION_STATUS: (state, status) =>
        (state.isObligationEditing = status),
    SET_EDIT_OBLIGATION_STATUS_CODE: (state, status) =>
        (state.obligationEditStatusCode = status),
    SET_EDIT_PROFILE_STATUS: (state, status) =>
        (state.isProfileEditing = status),
    SET_EDIT_PROFILE_STATUS_CODE: (state, status) =>
        (state.profileEditStatusCode = status),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
