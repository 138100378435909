import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import studentObligations from "./modules/student/obligations";
import students from "./modules/students";
import student from "./modules/dashboard/student";
import obligations from "./modules/dashboard/obligations";
import payments from "./modules/dashboard/payments";
import report from "./modules/dashboard/report";
import createStudent from "./modules/createUser"; // cineon//Import the new module

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        studentObligations,
        students,
        payments,
        student,
        obligations,
        report,
        createStudent,
    },
});
