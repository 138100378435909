import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 350);
        });
    },
});

router.beforeEach(async (to, from, next) => {
    const ignoreRoutes = ["/login", "/register", "/password/reset"];
    if (!ignoreRoutes.includes(to.path)) {
        if (!store.getters.isAuthenticated) {
            await store.dispatch("fetchLoggedUser");
        }
        if (store.getters.isAuthenticated) {
            if (to.name !== "Student" && store.getters.isStudent)
                next({ name: "Student" });
            else next();
            if (to.name !== "Profesor" && store.getters.isProfesor)
                next({ name: "Profesor" });
            else next();
            if (to.name === "PresetsAdd" && store.getters.isSupervizor)
                next({ name: "Home" });
            else next();
            if (
                (to.name === "Student" || to.name === "Profesor") &&
                (store.getters.isSupervizor || store.getters.isAdmin)
            )
                next({ name: "Home" });
            else next();
        }
    }
});

export default router;
