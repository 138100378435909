import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    students: [],
    isFetching: false
};

const getters = {
    allStudents: state => state.students,
    studentsFetchStatus: state => state.isFetching
};

const actions = {
    async fetchStudents({ commit, state }) {
        try {
            state.isFetching = true;
            const response = await axios.get(`${api}/students`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_STUDENTS", response.data);
            state.isFetching = false;
        } catch (error) {
            console.log("Couldn't get students list");
            state.isFetching = false;
        }
    }
};

const mutations = {
    SET_STUDENTS: (state, students) => (state.students = students)
};

export default {
    state,
    getters,
    actions,
    mutations
};
