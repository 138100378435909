<!-- Popup.vue -->
<template>
    <div>
        <div v-if="showPopup" class="overlay"></div>
        <div v-if="showPopup" class="popup">
            <div class="popup-content">
                <p>Sesija istekla. Prijavite se ponovo.</p>
                <button @click="signIn">Prijava</button>
                <button @click="closePopup">Zatvori</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            api: process.env.MIX_API_URL,
            showPopup: false,
            sessionCheckInterval: null // Store interval ID for session check
        };
    },
    methods: {
        signIn() {
            console.log("Signing in...");
            location.reload();
        },
        closePopup() {
            this.showPopup = false;
        },
        openPopup() {            
            this.showPopup = true;
        },
        startSessionCheck() {
            this.sessionCheckInterval = setInterval(this.checkSessionStatus, 60000);
        },
        stopSessionCheck() {
            clearInterval(this.sessionCheckInterval);
        },
        handleSessionExpired() {
            this.showPopup = true;
            console.log('Session has expired. Triggering function...');
            this.stopSessionCheck();
        },
        async checkSessionStatus() {
            try {
                const response = await axios.get(`/cineonSessionChecker`);
               
            } catch (err) {
                if (err.response.status === 401) {
                    this.handleSessionExpired();
                } else {
                    console.log("There was an error while trying to fetch", err);
                }
            }
        },
        async cineonQuickAuthTest() {
            try {
                const response = await axios.get(`/main/SuspendUser/836`);
               console.log(response)
            } catch (err) {
                if (err.response.status === 401) {
                  //  this.handleSessionExpired();
                } else {
                    console.log("There was an error while trying to fetch", err);
                }
            }
        },
        logout() {
            this.stopSessionCheck();
            // Perform logout actions...
        }
    },
    mounted() {
        window.cineon = () => {      
          //  this.openPopup();  
          this.cineonQuickAuthTest()
        }
        this.startSessionCheck();
    }
};

</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-width: 450px;
    width: 100%;
    height: 20%;
}

.popup-content {
    text-align: center;
    width: 90%;
}

.message {
    font-size: 18px;
    margin-bottom: 20px;
   
}

.button-group {
    display: flex;
    justify-content: center;
}

button {
    margin: 5px;
    margin-top: 25px;
    padding: 10px 20px;
    border: none;
   border: 1px #dbdbdb solid;
    color: black;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #1d4c84 ;
    color:white;
}
</style>