require("./bootstrap");

import Vue from "vue";
import VueRouter from "vue-router";
import router from "./router/index";
import store from "./store/index";
import App from "./App.vue";
import VueContentPlaceholders from "vue-content-placeholders";

Vue.use(VueRouter);
Vue.use(VueContentPlaceholders);

import Vuetify from "./plugins/vuetify";

Vue.mixin({
    methods: {
        between: (x, min, max) => x >= min && x <= max,
        getColor(status) {
            if (status === 1)
                return "green lighten-4 green--text text--darken-4";
            else if (status === 2 || status === 5)
                return "orange lighten-4 orange--text text--darken-4";
            else if (status === 3 || status === 4)
                return "red lighten-4 red--text text--darken-4";
        },
        getColor2(status) {
            if (status === 1)
                return "green lighten-4 green--text text--darken-4";
            else if (status === 2)
                return "red lighten-4 red--text text--darken-4";
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
    },
    data() {
        return {
            formValues: {
                yearOfStudyItems: [
                    { text: "Nije dodjeljeno", value: 6, ciklus: "" },
                    { text: "1", value: 1, ciklus: "Dodiplomski" },
                    { text: "2", value: 2, ciklus: "Dodiplomski" },
                    { text: "3", value: 3, ciklus: "Dodiplomski" },
                    { text: "4", value: 4, ciklus: "Dodiplomski" },
                    { text: "5", value: 5, ciklus: "Master" },
                    { text: "6", value: 7, ciklus: "Master" },
                    { text: "7", value: 8, ciklus: "Doktorski studij" },
                ],
                statusItems: [
                    { text: "Redovni", value: 1 },
                    { text: "Redovan samofinansirajući", value: 2 },
                    { text: "Vanredan", value: 3 },
                    { text: "Redovni obnova", value: 5 },
                    { text: "Redovan samofinansirajući obnova", value: 6 },
                    { text: "Vanredan obnova", value: 7 },
                    { text: "Apsolvent", value: 8 },
                ],
                obligationStatusItems: [
                    { text: "Aktivno", value: 1 },
                    { text: "Neaktivno", value: 2 },
                ],
                cycles: [
                    { text: "Prvi ciklus", value: 1 },
                    { text: "Drugi ciklus", value: 2 },
                    { text: "Treći ciklus", value: 3 },
                ],
                accountStatusItems: [
                    { text: "Aktivan", value: 1 },
                    { text: "Suspendovan", value: 2 },
                    { text: "Nije dodijeljeno", value: 3 },
                    { text: "Arhivirano", value: 4 },
                ],
            },
            today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
        };
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.today);
        },
    },
});

const app = new Vue({
    el: "#app",
    vuetify: Vuetify,
    store,
    router,
    components: { App },
});
