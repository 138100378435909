import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import ba from "vuetify/lib/locale/ba";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#2E2F39", //"#1D4C84", //boja template-a cineon
                secondary: colors.red.lighten4,
                accent: colors.indigo.base,
                background: colors.grey.lighten5,
            },
            dark: {
                primary: colors.blue.lighten3,
                background: colors.indigo.base, // If not using lighten/darken, use base to return hex
            },
        },
    },
    lang: {
        locales: { ba },
        current: "ba",
    },
};

export default new Vuetify(opts);
