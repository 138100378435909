import axios from "axios";
const api = process.env.MIX_API_URL;
const state = {
    createStudentStatusCode: null,
};

const getters = {
    createStudentStatusCode: (state) => state.createStudentStatusCode,
};

const actions = {
    async createUser({ commit, state }, userData) {
        try {
            state.isPosting = true; // Set posting status to true
            const response = await axios.post(`${api}/createUser`, userData);
            // Handle response as needed
            console.log("User created successfully:", response.data);
            commit("SET_CREATE_STUDENT_STATUS_CODE", 200);
            state.isPosting = false; // Set posting status back to false
        } catch (error) {
            // Handle error
            console.error("Error creating user:", error);
            state.isPosting = false; // Set posting status back to false

            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                console.log(error.response.data.message); // Display error message to the user

                commit("SET_CREATE_STUDENT_STATUS_CODE", 400);
            } else {
                //    alert("An error occurred while creating the user."); // Fallback error message
            }
        }
    },
};

const mutations = {
    SET_CREATE_STUDENT_STATUS_CODE: (state, code) => {
        state.createStudentStatusCode = code;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
