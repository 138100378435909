import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    user: null,
    userWithId: null, // New variable to hold user with ID
    isFetching: false,
    isAuthenticated: false,
};

const getters = {
    user: (state) => state.user,
    userWithId: (state) => state.userWithId, // Getter for user with ID
    userFetchStatus: (state) => state.isFetching,
    userTypeID: (state) => state.user.user_type.id,
    isAuthenticated: (state) => state.isAuthenticated,
    isSupervizor: (state) => state.user.user_type.id === 1,
    isAdmin: (state) => state.user.user_type.id === 2,
    isProfesor: (state) => state.user.user_type.id === 3,
    isStudent: (state) => state.user.user_type.id === 4,
};

const actions = {
    async fetchLoggedUser({ commit, state }) {
        try {
            commit("SET_FETCHING", true);
            const response = await axios.get(`/user`);
            commit("SET_USER", response.data[0]);
            commit("SET_FETCHING", false);
            commit("SET_AUTHENTICATED", true);
        } catch (error) {
            console.log("Couldn't get logged user");
        }
    },

    async fetchUserById({ commit }, userId) {
        try {
            const response = await axios.get(`/user/${userId}`);
            // console.log("User Data:", response.data); // Log user data
            commit("SET_USER_WITH_ID", response.data); // Store user with ID
            return response.data;
        } catch (error) {
            console.error(`Error fetching user with ID ${userId}:`, error);
            return null;
        }
    },
};

const mutations = {
    SET_USER: (state, user) => (state.user = user),
    SET_USER_WITH_ID: (state, userData) => {
        state.userWithId = userData;
    }, // Mutation for user with ID
    SET_AUTHENTICATED: (state, isAuthenticated) =>
        (state.isAuthenticated = isAuthenticated),
    SET_FETCHING: (state, isFetching) => (state.isFetching = isFetching),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
