<template>
  <v-container fill-height fluid class="center">
    <v-row justify="center" align="center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.center {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
}
</style>