import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    obligations: [],
    payments: [],
    isFetching: false,
    isUploading: false,
    uploadStatus: null
};

const getters = {
    allObligations: state => state.obligations,
    obligationsFetchStatus: state => state.isFetching,
    obligationsisUploading: state => state.isUploading,
    obligationUploadStatus: state => state.uploadStatus,
    studentPayments: state => state.payments
};

const actions = {
    async fetchObligations({ commit, state }, id) {
        try {
            state.isFetching = true;
            const response = await axios.get(`${api}/user/obligations/${id}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            const obligations = JSON.parse(JSON.stringify(response.data));
            commit("SET_OBLIGATIONS", obligations);
            state.isFetching = false;
        } catch (error) {
            console.log(error);
            state.isFetching = false;
        }
    },
    async fetchStudentPayments({ commit, state }) {
        try {
            state.isFetching = true;
            const response = await axios.get(`${api}/getstudent/payments`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            const payments = JSON.parse(JSON.stringify(response.data));
            commit("SET_PAYMENTS", payments);
            state.isFetching = false;
        } catch (error) {
            state.isFetching = false;
            console.log("User doesn't have any payments");
        }
    },
    async verifyPayment({ state }, data) {
        const { amount, image, note, obligation_id } = data;
        let formData = new FormData();
        formData.append("amount", amount);
        formData.append("image", image);
        formData.append("note", note.trim());
        formData.append("obligation_id", obligation_id);
        try {
            state.isUploading = true;
            const response = await axios.post(`/submitpayment`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data; charset=utf-8;",
                    "Access-Control-Allow-Origin": "*"
                }
            });
            state.uploadStatus = response.status;
            state.isUploading = false;
        } catch (error) {
            state.isUploading = false;
            state.uploadStatus = error.response.status;
            console.log("Error while uploading");
        }
    },
    async verifyRejectedPayment({ state }, data) {
        const { obligation_id, image, note } = data;
        let formData = new FormData();
        formData.append("obligation_id", obligation_id);
        formData.append("image", image);
        formData.append("note", note.trim());
        try {
            state.isUploading = true;
            const response = await axios.post(`/payment/resubmit`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data; charset=utf-8;",
                    "Access-Control-Allow-Origin": "*"
                }
            });
            state.uploadStatus = response.status;
            state.isUploading = false;
        } catch (error) {
            state.isUploading = false;
            state.uploadStatus = error.response.status;
            console.log("Error while uploading");
        }
    }
};

const mutations = {
    SET_OBLIGATIONS: (state, obligations) => (state.obligations = obligations),
    SET_PAYMENTS: (state, payments) => (state.payments = payments),
    SET_STUDENT_OBLIGATION_UPLOAD_STATUS: (state, uploadStatus) =>
        (state.uploadStatus = uploadStatus)
};

export default {
    state,
    getters,
    actions,
    mutations
};
